import React from 'react';

import './Footer.css';

const Footer = () => {
  return (
    <div className='footer'>
    <h6>&copy; Friend Finder Team</h6>
    </div>

  )
}

export default Footer;